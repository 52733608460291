@import 'custom';
@import './pulsing-dots';

body {
    margin: 0;
    font-family: 'gotham', sans-serif;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: black;
}

.pointer {
  cursor: pointer;
}

.main-wrapper {
    background-size: cover;
    min-height: 100%;
    display: flex;
    align-items: center;
}

.thick-underline {
  padding-bottom: 2px;
  border-bottom: 3px solid black;
}

.font-weight-boldest {
  font-weight: 800 !important;
}

.letter-spacing-wider {
  letter-spacing: 0.1rem !important;
}

.flex-3row {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.flex-3row > main {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-3row > header,
.flex-3row > footer {
    padding: 2rem;
    padding-bottom: 1rem;
    width: 100%;
    background-color: white;
    box-shadow: 0 0 1rem rgba(0, 0, 0, .3);
}

header > .row > div,
footer > .row > div {
    padding-bottom: 1rem;
    text-align: center;
}

.footer img {
    max-width: 200px;
}

.description-text {
  font-family: gotham;
  font-weight: 300;
  font-size: 0.9rem;
}

.gotham-bold {
  font-family: gotham;
  font-weight: bold;
}

.vid-container,
.record-vid-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
    video {
        width: 100%;
        height: auto;
        max-height: 550px;
    }
}

.record-vid-container {
    max-width: 670px;
}

.product-selection-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    grid-gap: 40px;
    justify-content: center;
}
.product-thumbs > div {
    margin-bottom: 15px;
}
.product-thumb {
  max-width: 5rem !important;
}

button:focus, input[type="button"]:focus {
  outline: none;
}
.selection {
    width: 100%;
    max-width: 420px;
    background-color: white;
    padding: 4px 4px 7px;
    border-radius: 10px;
    box-shadow: 0 0 1rem rgba(0, 0, 0, .3);
    margin-bottom: 40px;
    > a > img {
        width: 100%;
        height: auto;
    }
}
.selection:only-child {
    width: 100%;
}

// .selection:nth-child(odd) {
//     margin-right: 40px; 
// }

.error-page {
  background-color: black !important;
  color: white !important;
}

.error-message > strong {
  color: #fff!important;
  line-height: 1rem;
  text-align: center;
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  max-width: 80%;
  margin: 0 auto;
  font-family: Helvetica,Open-Sans,"sans-serif-light",sans-serif;
  font-size: .75rem;
  font-weight: 300;
  letter-spacing: .00625rem;
}

.error-message {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  max-width: 80%;
  margin: 0 auto;
  padding: 0 0 1.5rem;
  text-align: center;
  font-family: Helvetica,Open-Sans,"sans-serif-light",sans-serif;
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-weight: 300;
  letter-spacing: .00625rem;
  color: #fff!important;
}

.header-wrapper {
  border-bottom: 1px solid #333;
  padding: 0.8rem;
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: space-between;
  width: 100%;
}

.header-logo {
  height: 1.5rem;
}

.header-icons {
  display: inline-block;
  height: 1.5rem;
}

.header-items {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: space-between;
  list-style-type: none;
  align-items: center;
}

.pdp-wrapper {
    width: 100%;
    height: 100%;
    background-color: white;
}


.pdp-container {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 580px 5fr;
}

.pdp-header {
    margin-bottom: 1rem;
    > img {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 0 auto;
    }
}

.select-buttons {
    height: 100%;
    button {
        font-size: 100%;
        font-family: inherit;
        border: 0;
        padding: 0;
    }
}



.tracker-wrapper {
    width: 100vw;
    max-width: 480px;
    margin: 0 auto;
}

/* Tryon styles */
#tracker-div {
    min-height: 640px;
    cursor: pointer;
    margin-bottom: 15px;
    position: absolute;
    top: 0px;
    left: 0px;
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    max-width: 100vw;
    width: 100vw;
    height: 100vh; //calc(100vw * 640 / 480);
    overflow: hidden;
}
// make full height and center if vw < 480px
@media (max-width: 480px) {
    #tracker-div.no-compare {
        min-height: 480px;
        min-width: calc(100vw * 1.25); // 480px;
        margin-left: calc((100vw - 125%) / 2);
    }

    #tracker-div.compare-2x {
        min-height: 480px;
        min-width: calc(100vw * 2.5); // 480px;
        margin-left: calc((100vw - 100%) / 2);
    }
}
.compare-2x {
  margin-top: 0px !important;
  min-width: unset !important;
  max-width: 100vw !important;
}

.video-canvas-container {
  overflow: hidden;
}
#tracker-div > div {
    position: relative;
    float: left;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    margin: -2px;
    height: 100vh;
    width: 50%;
    cursor: pointer; /* ! Important for ios safari click even to register */
}
#tracker-div.compare-4x > div {
  height: 50vh !important;
}
#tracker-div > div:nth-child(2n + 3) {
    clear: left;
}
// #tracker-div > div.selected {
//     border-color: #c10128;
// }
#tracker-div:after {
    content: '';
    display: block;
    clear: both;
}
canvas, .hide {
    display: none;
}

#tracker-div > div > canvas {
    position: absolute;
    display: block;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: unset !important;
    max-width: unset !important;
    height: 100vh !important;
    max-height: 100vh !important;      
}
#tracker-div.compare-4x > div > canvas {
  height: 50vh !important;
}
  @media (min-aspect-ratio: 480/640) {
    #tracker-div > div > canvas {
      display: block;
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      //width: calc(100% * (640/480)) !important;
      min-width: 0;
      width: 100vw !important;
      max-width: 100vw !important;
      height: unset !important;
      max-height: unset !important;
    }
    #tracker-div.compare-4x > div > canvas {
      height: 50vh !important;
    }
    #tracker-div.compare-2x > div > canvas {
      width: unset !important;
      height: 100vh !important;
    }
  }

#tracker-div > div:only-child {
    width: 100%;
}
#profiler {
    clear: both;
}

// make full height and center if vw > 480
@media (min-width: 480px) {
    #videoCanvas-0 {
      max-height: 100vh !important;
    }
    #videoCanvas-1 {
      max-height: 100vh !important;
    }
    #tracker-div > div > canvas {
      display: block;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      width: unset !important;
      max-width: unset !important;
      height: 100vh !important;
      max-height: 100vh !important;      
    }
    #tracker-div.compare-4x > div > canvas {
      height: 50vh !important;
    }
    @media (min-aspect-ratio: 480/480) {
      #tracker-div > div > canvas {
        display: block;
        position: absolute !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        //width: calc(100% * (640/480)) !important;
        min-width: 0;
        width: 100vw !important;
        max-width: 100vw !important;
        height: unset !important;
        max-height: unset !important;
      }
      #tracker-div.compare-4x > div > canvas {
        height: 100% !important;
        width: unset !important;
      }
    }
}
// make full height and center if vw > 960
@media (min-width: 960px) {
    #tracker-div {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: auto !important;
      max-width: 200vh !important;
      height: 100vh !important;
      max-height: 100vh !important;
      /* min-height: 480px;
        min-width: 480px;
        margin-left: calc((100vw - 100%) / 2); */
    }

    .compare-2x {
      margin-top: 0px !important;
      min-width: unset !important;
      max-width: 200vh !important;
    }
    #tracker-div > div {
      float: left;
      border-width: 2px;
      border-style: solid;
      border-color: transparent;
      margin: -2px;
      width: 50%;
      min-height: 100vh !important;
      cursor: pointer; /* ! Important for ios safari click even to register */
    }
    #tracker-div.compare-4x > div {
      height: 50vh !important;
      min-height: 50% !important
    }
    #tracker-div > div > canvas {
      display: block;
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      //width: calc(100% * (640/480)) !important;
      min-width: 0;
      width: unset !important;
      max-width: unset !important;
      height: 100vh !important;
      max-height: 100vh !important;
    }
    @media (min-aspect-ratio: 640/480) {
      #tracker-div > div > canvas {
        display: block;
        position: absolute !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        //width: calc(100% * (640/480)) !important;
        min-width: 0;
        width: 100vw !important;
        max-width: 100vw !important;
        height: unset !important;
        max-height: unset !important;
      }
      #tracker-div.compare-2x > div > canvas {
        width: unset !important;
      }
      #tracker-div.compare-4x > div > canvas {
        width: 50vw !important;
        height: 50vh !important;
      }
    }
    #tracker-div.compare-4x > div > canvas {
      height: 50vh !important;
    }
    #tracker-div.compare-2x > div > canvas {
      display: block;
      height: calc(100vw / 2 * 1.3) !important;
      max-height: 100vh;
      top: 0px !important;
      transform: translate(-50%, 0%) !important;
    }
    #tracker-div > div:only-child {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100vw;
    }
}
@media (min-width: 1280px) {
    #tracker-div.compare-2x > div > canvas {
      width: 100vh !important;
      height: 100vh !important;
    }
}

/** ---------------------- **/
.products-wrapper {
    border: 1px solid #ccc;
}
.tracker-buttons {
    display: flex;
    align-items: center;
    padding-left: 5px;
}

.tracker-buttons .text {
    color: black;
    text-transform: uppercase;
    line-height: 1;
    padding-top: 3px;
    padding-right: 7px;
    flex-grow: 1;
    font-weight: 600;
    font-size: 1.1rem;
    letter-spacing: 0;
    margin-bottom: 10px;
}

.compare-buttons {
    align-self: flex-start;
    flex-shrink: 0;
    display: flex;
    align-items: flex-start;
    transform: translate(1px, -1px);
}
.compare-btn {
    display: block;
    border: 1px solid black;
    background-color: white;
    color: black;
    border-right: none;
    font-size: .89rem;
    font-weight: 600;
    line-height: 1.1;
}

.compare-btn.active {
    background-color: black;
    color: white;
}

.compare-btn:last-child {
    border-right: 1px solid #333;
}

.tracker-products {
    display: flex;
    justify-content: space-between;
}
.tracker-products .prodImage {
    border: 2px solid transparent;
    margin-bottom: 5px;
    width: 5.5rem;
    height: 5.5rem;
}
.tracker-products .prodImage.active {
    border-color: #2786b5;
}

div#alter-image { min-width: 100px; }
div#alter-image canvas {
    display: block;
    width: 100% !important;
    height: auto !important;
}


.suggestion-title {
    font-weight: bold;
    text-align: center;
    font-size: 1.2rem;
}

.controls {
    display: none;
}

.font-serif {
    font-family: serif !important;
}

.video-recorder {
    position: absolute;
    top: 0;
    width: 480px;
    height: 480px;
}

#iframe-container {
    position:relative;
    overflow: hidden;
}

// Bootstrap overrides
.navbar-nav .nav-link.nav-buttons {
    display: block;
    padding: 3px 25px;
    border: 1px solid #ccc;
    color: #0056b3;
    margin-left: 10px;
    text-transform: uppercase;
    font-size: 1.1rem;
    font-weight: 500;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, .3); 
}

.navbar-nav .active > .nav-link.nav-buttons {
    background: #0056b3;
    color: white;
}

.collapse:not(.show) {
    display: none;
}

/** MEDIA QUERIES **/
@media (max-width: 1200px) {
    .pdp-container {
        grid-template-rows: auto auto;
        grid-template-columns: 100px auto;
    }
    .product-details {
        grid-column: 1 / 3;
        margin-top: 2rem;
    }
    .main { min-height: 480px; }
    .tracker-wrapper > div:nth-child(1) {
        position: static !important;
    }
}
@media (max-width: 1090px) {
    .navbar-nav { margin-top: 10px; margin-bottom: 10px; }
    .navbar-nav .nav-link.nav-buttons {
        box-shadow: none;
        border-bottom: none !important;
    }
    .navbar-nav li:last-child .nav-link.nav-buttons {
        border-bottom: 1px solid #ccc !important;
    }
}
@media (max-width: 768px) {
    .pdp-container {
        grid-template-rows: auto auto auto;
        grid-template-columns: auto;
    }
    .product-details {
        grid-column: unset;
    }
    .select-buttons {
        min-width: 0;
        height: 100%;
    }
    .select-menu {
        min-width: 0;
    }
    .product-description.glasses .split {
        flex-direction: column;
    }
    .product-description.glasses .split > a {
        margin-bottom: 10px;
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .header-wrapper {
    padding: 1rem;
  }
  
  .header-logo {
    height: 3rem;
  }
  
  .header-icons {
    height: 3rem;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .main-wrapper {
      height: 100%;
  }
  .footer img {
      max-width: 100%;
  }

  header > .row > div:first-child,
  footer > .row > div:first-child {
      text-align: left;
  }

  header > .row > div:last-child,
  footer > .row > div:last-child {
      text-align: right;
  }

  .selection { width: 46.9% }
  .selection:nth-child(odd) {
      margin-right: 40px; 
  }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  
}