#circleG{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height:60px;
  width:60px;
  margin-top: -40px;
}

.circleG{
	background-color:rgb(117,117,117);
	float:left;
	height:.625rem;
	margin: 0 .1875rem;
	width:.625rem;
	animation-name:bounce_circleG;
		-o-animation-name:bounce_circleG;
		-ms-animation-name:bounce_circleG;
		-webkit-animation-name:bounce_circleG;
		-moz-animation-name:bounce_circleG;
	animation-duration:1.5575s;
		-o-animation-duration:1.5575s;
		-ms-animation-duration:1.5575s;
		-webkit-animation-duration:1.5575s;
		-moz-animation-duration:1.5575s;
	animation-iteration-count:infinite;
		-o-animation-iteration-count:infinite;
		-ms-animation-iteration-count:infinite;
		-webkit-animation-iteration-count:infinite;
		-moz-animation-iteration-count:infinite;
	animation-direction:normal;
		-o-animation-direction:normal;
		-ms-animation-direction:normal;
		-webkit-animation-direction:normal;
		-moz-animation-direction:normal;
	border-radius:15px;
		-o-border-radius:15px;
		-ms-border-radius:15px;
		-webkit-border-radius:15px;
		-moz-border-radius:15px;
}

#circleG_1{
	animation-delay:0.3095s;
		-o-animation-delay:0.3095s;
		-ms-animation-delay:0.3095s;
		-webkit-animation-delay:0.3095s;
		-moz-animation-delay:0.3095s;
}

#circleG_2{
	animation-delay:0.7255s;
		-o-animation-delay:0.7255s;
		-ms-animation-delay:0.7255s;
		-webkit-animation-delay:0.7255s;
		-moz-animation-delay:0.7255s;
}

#circleG_3{
	animation-delay:0.9385s;
		-o-animation-delay:0.9385s;
		-ms-animation-delay:0.9385s;
		-webkit-animation-delay:0.9385s;
		-moz-animation-delay:0.9385s;
}



@keyframes bounce_circleG{
	0%{}

	50%{
		background-color:rgb(38,38,38);
	}

	100%{}
}

@-o-keyframes bounce_circleG{
	0%{}

	50%{
		background-color:rgb(38,38,38);
	}

	100%{}
}

@-ms-keyframes bounce_circleG{
	0%{}

	50%{
		background-color:rgb(38,38,38);
	}

	100%{}
}

@-webkit-keyframes bounce_circleG{
	0%{}

	50%{
		background-color:rgb(38,38,38);
	}

	100%{}
}

@-moz-keyframes bounce_circleG{
	0%{}

	50%{
		background-color:rgb(38,38,38);
	}

	100%{}
}